import { AuthContext } from '@/context/auth/AuthMachineProvider';
import Button from '@/shared/Button';
import { IconDefinition } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'lottie-react';

import { cn } from '@everfund/ui';

type StateTemplateIconRequiredProps = {
  icon?: IconDefinition;
  iconColor?: string;
};

type StateTemplateAnimationRequiredProps = {
  animation?: any;
};

export interface StateTemplateProps extends StateTemplateIconRequiredProps, StateTemplateAnimationRequiredProps {
  title?: string | null;
  description?: React.ReactNode;
  resetButton?: boolean;
}

const ButtonWithXState = () => {
  const [, send] = AuthContext.useActor();
  return (
    <Button buttonColor="blue" isFullWidth onClick={() => send('RESET')}>
      Try Again
    </Button>
  );
};
export const StateTemplate = (props: StateTemplateProps) => {
  return (
    <>
      <div className="space-y-3">
        {props?.icon && (
          <div className="mb-4 flex items-center">
            <FontAwesomeIcon icon={props.icon} className={cn('mx-auto h-32 w-32 p-3', props.iconColor)} />
          </div>
        )}
        {props?.animation && (
          <div className="mx-auto w-auto">
            <Lottie
              animationData={props?.animation}
              loop={0}
              style={{
                width: 144,
                height: 144,
                margin: '0 auto',
                borderRadius: '100%',
                overflow: 'hidden',
              }}
            />
          </div>
        )}
        <div>
          {props?.title && <h2 className="pb-4 text-center text-xl font-medium text-primary">{props.title}</h2>}
          {props.description && <p className="mb-6 text-center text-base text-secondary">{props.description}</p>}

          {props.resetButton && <ButtonWithXState />}
        </div>
      </div>
    </>
  );
};
