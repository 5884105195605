import { AuthContext } from '@/context/auth/AuthMachineProvider';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { SubmitHandler } from 'react-hook-form';
import { z } from 'zod';

import { useAnalytics } from '@everfund/event-detective';

import AuthForm, { AuthTextField } from './AuthForm';

export type SignInInputs = {
  emailAddress: string;
};

const SignIn = () => {
  const [, send] = AuthContext.useActor();
  const { track } = useAnalytics();
  const onSubmit: SubmitHandler<SignInInputs> = (data) => {
    track({
      name: 'clicked-sign-in',
      properties: {},
    });
    send({ type: 'SIGNIN_WITH_EMAIL', data });
  };
  const t = useTranslations('components.SignIn');

  const resolver = zodResolver(
    z.object({
      emailAddress: z
        .string({
          required_error: t('validation.email'),
        })
        .email({ message: t('validation.emailInvalid') }),
    })
  );

  return (
    <AuthForm<SignInInputs> onSubmit={onSubmit} resolver={resolver}>
      {({ register, formState: { errors } }) => {
        return (
          <>
            <AuthTextField<SignInInputs, 'emailAddress'>
              errors={errors}
              register={register('emailAddress')}
              inputType="email"
              label={t('label')}
            />
          </>
        );
      }}
    </AuthForm>
  );
};

export default SignIn;
