import { AuthContext } from '@/context/auth/AuthMachineProvider';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { Logo, cn } from '@everfund/ui';

export type SignInInputs = {
  emailAddress: string;
};

const TokenSignIn = () => {
  const [, send] = AuthContext.useActor();
  const signInToken = useSearchParams().get('token');
  const t = useTranslations('components.TokenSignIn');

  useEffect(() => {
    send({ type: 'SIGNIN_WITH_TOKEN', data: { signInToken } });
  }, [send, signInToken]);

  return (
    <>
      <Logo className={cn('mx-auto h-auto max-h-10 w-auto mb-4')} textClassName="text-strength dark:text-white" />
      <h2 className="text-center text-2xl text-primary">{t('message')}</h2>
    </>
  );
};

export default TokenSignIn;
