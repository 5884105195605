import CheckEmailState from '@/layouts/Unauthenticated/views/CheckEmailState';
import {
  VerifyLinkExpiredState,
  VerifyLinkFailedState,
  VerifyLinkSuccessState,
} from '@/layouts/Unauthenticated/views/LinkStatusState';
import { LoadingState, LoadingStateFullScreen } from '@/layouts/Unauthenticated/views/LoadingState';
import MagicFlowExpiredState from '@/layouts/Unauthenticated/views/MagicFlowExpiredState';
import NetworErrorState from '@/layouts/Unauthenticated/views/NetworErrorState';
import * as RedirectStates from '@/layouts/Unauthenticated/views/RedirectStates';
import SignIn from '@/layouts/Unauthenticated/views/SignIn';
import SignUp from '@/layouts/Unauthenticated/views/SignUp';
import { createElement } from 'react';

import TokenSignIn from './views/TokenSignIn';

export {
  CheckEmailState,
  VerifyLinkFailedState,
  LoadingState,
  LoadingStateFullScreen,
  RedirectStates,
  VerifyLinkSuccessState,
  SignUp,
  SignIn,
  NetworErrorState,
  MagicFlowExpiredState,
};

export const AuthenticateViews: Record<string, () => JSX.Element | null> = {
  //authFlow
  authFlow_signIn: SignIn,
  authFlow_signUp: SignUp,
  authFlow_tokenSignIn: TokenSignIn,
  authFlow_startMagicFlow: CheckEmailState,
  authFlow_magicFlowExpired: MagicFlowExpiredState,

  // Redirect States for authFlow
  redirect_onboarding: RedirectStates.OnboardingState as () => never,
  redirect_unAuthenticated: RedirectStates.UnAuthenticatedState as () => never,
  redirect_authenticatedOnStart: RedirectStates.RedirectToRedirectFlow as () => never,

  //tabVerification
  verification_StatusSuccess: VerifyLinkSuccessState,
  verification_StatusFailed: VerifyLinkFailedState,
  verification_StatusExpired: VerifyLinkExpiredState,

  // multiple Pages
  utils_loadingState: LoadingState,
  utils_loadingState__fullscreen: LoadingStateFullScreen,
  utils_networkError: NetworErrorState,
};

interface RenderAuthProps {
  authState: string;
}

const RenderAuth = ({ authState }: RenderAuthProps) => {
  // authState does exist
  if (typeof AuthenticateViews[authState] !== 'undefined') {
    return createElement(AuthenticateViews[authState], {
      key: authState,
      block: authState,
    });
  } else {
    return createElement(AuthenticateViews['idle'], {
      key: 'utils_networkError',
      block: 'utils_networkError',
    });
  }
};

export default RenderAuth;
