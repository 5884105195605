import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-thin-svg-icons';
import { useTranslations } from 'next-intl';

import { StateTemplate } from './StateTemplate';

export const VerifyLinkExpiredState = () => {
  const t = useTranslations('components.VerifyLinkExpiredState');
  return (
    <StateTemplate
      icon={faCircleXmark}
      iconColor="text-red-500 dark:text-red-300"
      title={t('title')}
      description={t('description')}
    />
  );
};

export const VerifyLinkFailedState = () => {
  const t = useTranslations('components.VerifyLinkFailedState');
  return (
    <StateTemplate
      icon={faCircleXmark}
      iconColor="text-red-500 dark:text-red-300"
      title={t('title')}
      description={t('description')}
    />
  );
};

export const VerifyLinkSuccessState = () => {
  const t = useTranslations('components.VerifyLinkSuccessState');
  return (
    <StateTemplate icon={faCircleCheck} iconColor="text-green-300" title={t('title')} description={t('description')} />
  );
};
