import { AuthContext } from '@/context/auth/AuthMachineProvider';
import { EVERFUND_MARKETING_DOMAIN } from '@/context/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { SubmitHandler } from 'react-hook-form';
import { z } from 'zod';

import { useAnalytics } from '@everfund/event-detective';
import { cn } from '@everfund/ui';

import AuthForm, { AuthTextField } from './AuthForm';
import { SignInInputs } from './SignIn';

export type SignUpInputs = {
  firstName: string;
  lastName: string;
  agreedToLegals: boolean;
} & SignInInputs;

const SignUp = () => {
  const { track } = useAnalytics();
  const [, send] = AuthContext.useActor();
  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    track({
      name: 'clicked-sign-up',
      properties: {},
    });

    send({ type: 'SIGNUP_WITH_EMAIL', data });
  };
  const t = useTranslations('components.SignUp');

  const resolver = zodResolver(
    z.object({
      emailAddress: z
        .string({
          required_error: t('validation.email'),
        })
        .email({ message: t('validation.emailInvalid') }),
      firstName: z
        .string({
          required_error: t('validation.firstName'),
        })
        .min(2, { message: t('validation.firstNameInvalid') }),
      lastName: z
        .string({
          required_error: t('validation.lastName'),
        })
        .min(2, { message: t('validation.lastNameInvalid') }),
      agreedToLegals: z.literal(true, {
        required_error: t('validation.legal'),
      }),
    })
  );

  return (
    <>
      <AuthForm<SignUpInputs> onSubmit={onSubmit} resolver={resolver}>
        {({ register, formState: { errors } }) => {
          return (
            <>
              <AuthTextField<SignInInputs, 'emailAddress'>
                errors={errors}
                register={register('emailAddress')}
                inputType="email"
                label={t('fields.email')}
              />

              <div className="flex flex-row space-x-4">
                <AuthTextField<SignInInputs, 'firstName'>
                  errors={errors}
                  register={register('firstName')}
                  inputType="text"
                  label={t('fields.firstName')}
                />
                <AuthTextField<SignInInputs, 'lastName'>
                  errors={errors}
                  register={register('lastName')}
                  inputType="text"
                  label={t('fields.lastName')}
                />
              </div>
              <fieldset className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    {...register('agreedToLegals')}
                    name="agreedToLegals"
                    type="checkbox"
                    className={cn(
                      'sentry-mask h-4 w-4 rounded border-2 border-gray-300 text-blue-600 checked:border-blue-600 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-700 dark:ring-offset-nord-3 dark:checked:border-blue-600 dark:checked:bg-blue-600',
                      errors['agreedToLegals'] && '!border-red-500'
                    )}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="agreedToLegals"
                    className={cn('text-secondary', errors['agreedToLegals'] && 'text-red-500 dark:text-red-400')}
                  >
                    I have read and agreed to the{' '}
                    <a
                      href={`https://${EVERFUND_MARKETING_DOMAIN}/legal/privacy-policy`}
                      className="text-blue-600 hover:text-blue-500"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href={`https://${EVERFUND_MARKETING_DOMAIN}/legal/terms-and-conditions`}
                      className="text-blue-600 hover:text-blue-500"
                    >
                      Terms & Conditions
                    </a>
                    .
                  </label>
                </div>
              </fieldset>
            </>
          );
        }}
      </AuthForm>
    </>
  );
};

export default SignUp;
