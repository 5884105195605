import { useMutation } from '@tanstack/react-query';

const useSignedIn = () => {
  return useMutation<{ message: string }, unknown, unknown>({
    mutationFn: async () => {
      return await fetch('/api/users/signIn', {
        method: 'PATCH',
      }).then((res) => res.json());
    },
    mutationKey: ['signIn'],
    onError: () => {},
  });
};

export default useSignedIn;
