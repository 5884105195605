'use client';

import Image from 'next/image';

interface UnauthenticatedModalProps {
  children: React.ReactNode;
}

const UnauthenticatedModal = ({ children }: UnauthenticatedModalProps) => {
  return (
    <div className="relative px-4 pt-8 sm:w-full lg:p-0 ">
      <span className="absolute left-[-4rem] top-[-3rem] z-0 hidden md:block lg:w-[38rem]">
        <Image
          src="https://ik.imagekit.io/everfund/website/EF_Illustration_-_ROI_-_Clear_wY9pOBRTeH.png?tr=w-750"
          layout="constrained"
          width={608}
          height={531}
          alt="background"
          className="pointer-events-none select-none"
        />
      </span>

      <div className="relative z-10 mx-auto w-full rounded-xl bg-white p-8 pb-4 shadow dark:bg-nord-3 md:w-[25rem] md:py-14">
        {children}
      </div>
    </div>
  );
};

export default UnauthenticatedModal;
