import { faWarning } from '@fortawesome/pro-thin-svg-icons';
import { useTranslations } from 'next-intl';

import { StateTemplate } from './StateTemplate';

const NetworErrorState = () => {
  const t = useTranslations('components.NetworErrorState');
  return (
    <StateTemplate
      icon={faWarning}
      iconColor="text-secondary"
      title={t('title')}
      description={t('description')}
      resetButton
    />
  );
};

export default NetworErrorState;
