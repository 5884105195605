import { AuthContext } from '@/context/auth/AuthMachineProvider';
import { useTranslations } from 'next-intl';

import mailAnimation from './CheckEmailState.animation.json';
import { StateTemplate } from './StateTemplate';

const CheckEmailState = () => {
  const stateFlowType = AuthContext.useSelector((state) => state.context.type);
  const stateEmailAddress = AuthContext.useSelector((state) => state.context.emailAddress);
  const t = useTranslations('components.CheckEmailState');

  const linkType = stateFlowType === 'signIn' ? 'sign up' : 'sign in';

  return (
    <StateTemplate
      animation={mailAnimation}
      title="Check your email"
      description={
        <p>
          {t.rich('message', {
            linkType,
            stateEmailAddress,
            email: (chunk) => <span className="font-semibold text-ever-500">{chunk}</span>,
          })}
        </p>
      }
    />
  );
};

export default CheckEmailState;
