import { CreateUserResponse, CreateUserInput } from '@/services/users/create';
import { useMutation } from '@tanstack/react-query';

const useCreateUser = () => {
  return useMutation<CreateUserResponse, unknown, CreateUserInput>({
    mutationFn: async (variables) =>
      (
        await fetch('/api/users/createUser', {
          method: 'POST',
          body: JSON.stringify(variables),
        })
      ).json(),
    mutationKey: ['createUser'],
  });
};

export default useCreateUser;
