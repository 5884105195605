import { redirect } from '@/navigation';

// We need to redirect user based upon sign up flow state
export const OnboardingState = () => {
  redirect('/onboarding');
};

export const UnAuthenticatedState = () => {
  redirect('/sign-in') as never;
};

export const RedirectToRedirectFlow = () => {
  redirect('/authentication/redirect');
};
