import { ValidateLoginResponse } from '@/services/users/validateLogin';
import { ValidateLoginInput } from '@/services/users/validateLogin';
import { useMutation } from '@tanstack/react-query';

const useValidateLogin = () => {
  return useMutation<ValidateLoginResponse, unknown, ValidateLoginInput>({
    mutationFn: async (variables) =>
      (await fetch(`/api/users/validateLogin?email=${encodeURIComponent(variables.email)}`)).json(),
    mutationKey: ['validateLogin'],
  });
};

export default useValidateLogin;
